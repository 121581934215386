import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-3" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body pt-0" }
const _hoisted_5 = { class: "col-xxl-9" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "card-header border-0 pt-6" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "card-body pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgentTree = _resolveComponent("AgentTree")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AgentTree, { onSelectAgent: _ctx.onSelectAgent }, null, 8, ["onSelectAgent"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_DateRangePicker, {
                  onChangedate: _ctx.selectDate,
                  initial: "month",
                  showshortcuts: true
                }, null, 8, ["onChangedate"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            "enable-items-per-page-dropdown": true,
            "rows-per-page": 50,
            columnSum: true
          }, null, 8, ["table-data", "table-header"])
        ])
      ])
    ])
  ]))
}