
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import AgentTree from "@/components/agents/AgentTree.vue";
import moment from "moment";

interface ISettlement {
  betamount: string;
  winamount: string;
  profit: string;
  rolling: string;
  profit_final: string;
  createdAt: string;
}

interface DateRange {
  start: string;
  end: string;
}

export default defineComponent({
  name: "Settlement",
  components: {
    Datatable,
    DateRangePicker,
    AgentTree,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    const tableData = ref<Array<ISettlement>>([]);
    const tableHeader = ref([
      {
        name: t("BetHistory.Date"),
        key: "createdAt",
        text: true,
      },
      {
        name: t("Agents.BetAmount"),
        key: "betamount",
        currency: true,
        columnSum: true,
      },
      {
        name: t("Agents.HitAmount"),
        key: "winamount",
        currency: true,
        columnSum: true,
      },
      {
        name: t("Agents.Profit"),
        key: "profit",
        currency: true,
        columnSum: true,
      },
      {
        name: t("Agents.RollingFee"),
        key: "rolling",
        currency: true,
        columnSum: true,
      },
      {
        name: t("Agents.FinalProfit"),
        key: "profit_final",
        currency: true,
        columnSum: true,
      },
    ]);

    let agent = "";
    let currentDate: DateRange = {
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };

    const selectDate = (daterange: DateRange) => {
      currentDate = daterange;
      getSettlement(currentDate, agent);
    };

    const onSelectAgent = (selected_agent: any) => {
      if (agent !== selected_agent.id) {
        agent = selected_agent.id;
        getSettlement(currentDate, agent);
      }
    };

    /**
     * Get Settlement
     *
     */
    const getSettlement = async (daterange: DateRange, agent: string) => {
      let agent_str = "";
      if (agent) {
        agent_str = `&agent=${agent}`;
      }
      const results = await ApiService.get(
        `/agent/settlement/history?start=${daterange.start}&end=${daterange.end}${agent_str}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
    };
    /**
     * On Mounted
     *
     */
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.SettlementManage"), [
        t("Menu.AgentManage"),
      ]);
      getSettlement(
        {
          start: moment().startOf("month").format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        ""
      );
    });

    return {
      tableData,
      tableHeader,
      getSettlement,
      selectDate,
      onSelectAgent,
    };
  },
});
